<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    :variant="`transparent`"
    :opacity="0.5"
    :blur="`10px`"
    rounded="sm"
    spinner-type="grow"
  >
    <section>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              xl="9"
              md="8"
            >
              <b-card
                no-body
              >
                <b-card-body>
                  <div class="row">
                    <div class="col-12">
                      <validation-provider
                        #default="validationContext"
                        name="title"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.title')"
                          label-for="title"
                        >
                          <b-form-input
                            id="title"
                            v-model="inputData.title"
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="$t('common.form.please_insert_data')"
                            @input="onInputTitle"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <b-form-group
                        :label="$t('common.general.balise_title')"
                        label-for="baliseTitle"
                      >
                        <b-form-input
                          id="baliseTitle"
                          v-model="inputData.baliseTitle"
                          trim
                          :placeholder="$t('common.form.please_insert_data')"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <validation-provider
                                #default="validationContext"
                                name="slug"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('common.general.slug')"
                                  label-for="slug"
                                >
                                  <b-form-input
                                    id="slug"
                                    v-model="inputData.slug"
                                    :state="
                                      getValidationState(validationContext)
                                    "
                                    trim
                                    :placeholder="
                                      $t('common.form.please_insert_data')
                                    "
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12"> 
                              <b-form-group
                                :label="$t('common.general.keywords')"
                                label-for="keywords"
                              >
                                <b-form-tags
                                  v-model="inputData.keywords"
                                  input-id="tags-basic"
                                  class="mb-2"
                                /> 
                              </b-form-group> 
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <validation-provider
                                #default="validationContext"
                                name="description"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('common.general.description')"
                                  label-for="description"
                                >
                                  <b-form-textarea
                                    id="description"
                                    v-model="inputData.description"
                                    :state="
                                      getValidationState(validationContext)
                                    "
                                    :placeholder="
                                      $t('common.form.please_insert_data')
                                    "
                                    rows="5"
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <b-media
                        no-body
                        class="mb-75"
                      >
                        <b-media-aside>
                          <b-overlay
                            :show="isUpload"
                            class="d-inline-block"
                          >
                            <b-img
                              rounded
                              :src="
                                inputData.image ? inputData.image : imageDefault
                              "
                              height="80"
                            />
                          </b-overlay>
                        </b-media-aside>

                        <b-media-body class="mt-75 ml-75">
                          <b-form-group class="mb-0">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              size="sm"
                              class="mb-75 mr-75"
                              @click="$refs.refInputEl.$el.click()"
                            >
                              {{ $t('common.general.upload') }}
                            </b-button>
                            <b-form-file
                              ref="refInputEl"
                              accept=".jpg, .png"
                              :hidden="true"
                              plain
                              @input="upload"
                            />
                          </b-form-group>
                          <b-card-text>Allowed JPG or PNG.</b-card-text>
                        </b-media-body>
                      </b-media>
                    </div>
                    <div class="col-12">
                      <b-form-group
                        :label="$t('common.general.content')"
                        label-for="content"
                      >
                        <!-- <ckeditor
                          ref="article"
                          v-model="inputData.content"
                          :config="editorConfig"
                        /> -->
                        <textarea
                          ref="ckeditorForPage"
                          name="ckeditorForPage"
                          style="width:920px;"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="4"
              xl="3"
              class="invoice-actions mt-md-0 mt-2"
            >
              <b-card>
                <b-form-checkbox
                  v-model="inputData.statusCode"
                  value="ACTIVE"
                  unchecked-value="INACTIVE"
                  class="custom-control-primary mb-75"
                  name="check-button"
                  switch
                  inline
                >{{ $t("common.form.publish") }}
                </b-form-checkbox>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  type="submit"
                >
                  {{ $t("common.form.save") }}
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </section>
  </b-overlay>
</template>

<script>
import {
  BFormFile,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  VBToggle,
  BFormTags,
  BFormInvalidFeedback,
  BMedia,
  BMediaBody,
  BMediaAside,
  BImg,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import pageServices from '@/services/pageServices';
import commonServices from '@/services/commonServices';
import enums from '@/services/enumsServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { imageDefault, extraPlugins } from '@/services/constant';
export default {
  components: {
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormTags,
    BFormInvalidFeedback,
    BMedia,
    BMediaBody,
    BMediaAside,
    BImg,
    BFormCheckbox,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  data() {
    return {
      imageDefault,
      isLoading: false,
      id: this.$route.query.id,
      inputData: {},
      required,
      profileFile: '',
      isUpload: false,
      editorConfig: {
        language: 'en',
        extraPlugins,
        colorButton_enableMore: true,
        enterMode: 2,
      },
      ckInterval: null,
    };
  },
  watch: {
    data(value) {
      this.data = value;
      this.inputData = JSON.parse(JSON.stringify(this.data));
      return this.data;
    },
  },
  async mounted() {
    if (this.id) {
      await this.getDetails();
    } else {
      // eslint-disable-next-line no-undef
      await CKEDITOR.replace('ckeditorForPage', commonServices.getDefaultCkeditorConfig());
    }
    this.ckInterval = commonServices.createCkEditorImageBrowse(); 
  },
  beforeDestroy(){
    // eslint-disable-next-line no-undef
    if (CKEDITOR.instances.ckeditorForPage) CKEDITOR.instances.ckeditorForPage.destroy();
    // if (this.ckInterval) clearInterval(this.ckInterval);
    // if (this.inputData.id && !_.isEqual(this.originalInputData, this.inputData)){
    //   console.log('autosave')
    //   this.onSubmit();
    // } else {
    //   console.log('don\'t autosave')
    // }
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      if (!this.inputData.statusCode) {
        this.inputData.statusCode = 'INACTIVE';
      }
      // eslint-disable-next-line no-undef
      this.inputData.content = CKEDITOR.instances.ckeditorForPage.getData();
      await pageServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              this.$router.push({ name: 'list-pages' });
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async upload($event) {
      this.isUpload = true;
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = async () => {
        await commonServices
          .uploadImage($event, 'page', enums.levelS3.public)
          .then((result) => {
            this.isUpload = false;
            this.inputData.image = result;
          })
          .finally(() => {
            this.uploading = false;
          });
      };
    },
    async getDetails() {
      this.isLoading = true;
      await pageServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getPage;
            if (this.inputData.keywords) {
              this.inputData.keywords = JSON.parse(this.inputData.keywords);
            }
            // eslint-disable-next-line no-undef
            CKEDITOR.replace('ckeditorForPage', commonServices.getDefaultCkeditorConfig(this.inputData.content));
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    onInputTitle() {
      this.inputData.slug = commonServices.slugify(this.inputData.title);
    },
  },
};
</script>
